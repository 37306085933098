import React from 'react'
import PropTypes from 'prop-types'
import { GatsbyImage } from 'gatsby-plugin-image'

import SwiperCore, { Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

SwiperCore.use([Pagination])

const MobileGallery = ({ gallery }) => {
  return (
    <>
      <Swiper
        autoHeight
        pagination={{
          el: '.swiper-pagination.MobileGallery',
          type: 'bullets',
          clickable: true,
        }}
        watchOverflow
      >
        {gallery.map(({ id, childImageSharp, previewSrc }) => {
          return (
            <SwiperSlide key={id}>
              {previewSrc ? (
                <img src={previewSrc} alt="" className="w-full h-auto" />
              ) : (
                <GatsbyImage image={childImageSharp.gatsbyImageData} alt="" />
              )}
            </SwiperSlide>
          )
        })}
      </Swiper>
      <div className="swiper-pagination MobileGallery"></div>
    </>
  )
}

MobileGallery.propTypes = {
  gallery: PropTypes.array.isRequired,
}

export default MobileGallery
