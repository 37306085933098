import React from 'react'
import PropTypes from 'prop-types'
import { getFormattedPrice } from '../../utils/getFormattedPrice'

const globalDiscountPct = parseInt(
  process.env.GATSBY_GLOBAL_DISCOUNT_PCT || '0',
  10
)

const ProductTitle = ({ title, color, price }) => {
  return (
    <div className="py-12">
      <div className="flex">
        <div>
          <h1 className="text-3xl lg:text-5xl 2xl:text-6xl uppercase tracking-tight leading-none">
            {title}
          </h1>
          {color && (
            <div className="text-xs lg:text-sm text-right uppercase leading-none">
              {color}
            </div>
          )}
        </div>
      </div>
      {globalDiscountPct > 0 &&
      !(
        title?.toLowerCase().includes('gift cards') ||
        title?.toLowerCase().includes('tarjetas regalo')
      ) ? (
        <div className="mt-8 flex text-lg 2xl:text-xl">
          <div className="line-through mr-2 text-terracotta">{price}</div>
          <div>
            {getFormattedPrice(
              parseFloat(price) - (globalDiscountPct / 100) * parseFloat(price)
            )}
          </div>
        </div>
      ) : (
        <div className="mt-8 text-lg 2xl:text-xl">
          {getFormattedPrice(price)}
        </div>
      )}
    </div>
  )
}

ProductTitle.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string,
  price: PropTypes.string,
}

export default ProductTitle
