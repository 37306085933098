/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useRef } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { GatsbyImage, getSrc, getSrcSet } from 'gatsby-plugin-image'
import { CustomGallery, Item, DefaultLayout } from 'react-photoswipe-gallery'
import PhotoswipeUIDefault from 'photoswipe/dist/photoswipe-ui-default'

import 'photoswipe/dist/photoswipe.css'

import './photoswipe-skin.css'
import { container } from './DesktopGallery.module.scss'

const portalRoot =
  typeof document !== 'undefined' ? document.getElementById('portal') : null

const Portal = ({ children }) =>
  portalRoot ? ReactDOM.createPortal(children, portalRoot) : null

const DesktopGallery = ({ gallery, isPreview }) => {
  const layoutRef = useRef()

  if (isPreview) {
    return (
      <div className={container} style={{ cursor: 'default' }}>
        {gallery.map(({ previewSrc }, i) => (
          <img key={i} src={previewSrc} alt="" className="w-full h-auto" />
        ))}
      </div>
    )
  }

  return (
    <div className={container}>
      <CustomGallery
        layoutRef={layoutRef}
        ui={PhotoswipeUIDefault}
        options={{ barsSize: { top: 0, bottom: 0 } }}
      >
        {gallery.map(({ id, childImageSharp, previewSrc }, i) => {
          const {
            gatsbyImageData: { width, height },
          } = childImageSharp
          const getThumbnailSrc = () => {
            if (previewSrc) return previewSrc
            const srcSet = getSrcSet(childImageSharp)
            return srcSet.split(' ')[0]
          }

          return (
            <Item
              key={id || i}
              thumbnail={getThumbnailSrc()}
              original={previewSrc || getSrc(childImageSharp)}
              width={width * 1920}
              height={height * 1920}
            >
              {({ ref, open }) => (
                <div ref={ref} onClick={open}>
                  {previewSrc ? (
                    <img src={previewSrc} alt="" className="w-full h-auto" />
                  ) : (
                    <GatsbyImage
                      image={childImageSharp.gatsbyImageData}
                      alt=""
                    />
                  )}
                </div>
              )}
            </Item>
          )
        })}
      </CustomGallery>
      <Portal>
        <DefaultLayout ref={layoutRef} />
      </Portal>
    </div>
  )
}

DesktopGallery.propTypes = {
  gallery: PropTypes.array.isRequired,
  isPreview: PropTypes.bool,
}

DesktopGallery.defaultProps = {
  isPreview: false,
}

export default DesktopGallery
