import React from 'react'
import PropTypes from 'prop-types'

import SwiperCore, { Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import { getImage } from 'gatsby-plugin-image'

import getShopifyProductData from '../../utils/getShopifyProductData'
import LinkedProduct from '../LinkedProduct/LinkedProduct'

SwiperCore.use([Pagination])

const LinkedProductSlider = ({
  id,
  addVariantToCart,
  allShopifyProduct,
  nodes,
}) => {
  return (
    <>
      <Swiper
        autoHeight
        spaceBetween={30}
        pagination={{
          el: `.swiper-pagination.swiper-pagination-${id}`,
          type: 'bullets',
          clickable: true,
        }}
        watchOverflow
        breakpoints={{
          1100: {
            slidesPerView: 3,
          },
        }}
      >
        {nodes.map(node => {
          const { id, data, fields } = node
          const { title, main_gallery: mainGallery } = data
          const { pagePath, sku } = fields
          const shopifyData = getShopifyProductData(
            sku,
            allShopifyProduct.edges
          )
          const {
            price,
            productShopifyId,
            variantShopifyId,
            availableForSale,
          } = shopifyData
          let heroImage = null
          if (mainGallery.length) {
            const firstInGallery = mainGallery[0]
            if (firstInGallery.image && firstInGallery.image.localFile) {
              heroImage = getImage(firstInGallery.image.localFile)
            }
          }
          return (
            <SwiperSlide key={id}>
              <LinkedProduct
                availableForSale={availableForSale}
                heroImage={heroImage}
                addVariantToCart={addVariantToCart}
                pagePath={pagePath}
                price={price?.amount}
                productShopifyId={productShopifyId}
                variantShopifyId={variantShopifyId}
                title={title.text}
              />
            </SwiperSlide>
          )
        })}
      </Swiper>
      <div
        className={`-mt-6 sm:mt-0 swiper-pagination swiper-pagination-${id}`}
      ></div>
    </>
  )
}

LinkedProductSlider.propTypes = {
  id: PropTypes.string,
  addVariantToCart: PropTypes.func.isRequired,
  allShopifyProduct: PropTypes.object.isRequired,
  nodes: PropTypes.array.isRequired,
}

LinkedProductSlider.defaultProps = {
  id: '',
}

export default LinkedProductSlider
