import React from 'react'
import PropTypes from 'prop-types'
import useCollapse from 'react-collapsed'
import { Translate } from 'react-localize-redux'
import { detailsCloseIcon, content } from './ProductDetails.module.scss'

const shouldShowReadMore = (
  productDescription,
  details,
  careInstructions,
  personalNote
) => {
  if (!(productDescription || details || careInstructions || personalNote))
    return false

  if (
    productDescription === '' &&
    careInstructions === '' &&
    personalNote === '' &&
    (!details.length ||
      (details.length &&
        typeof details[0] === 'object' &&
        details[0] !== null &&
        !details[0].value))
  )
    return false

  return true
}

const ProductDetails = ({
  careInstructions,
  collabInfo,
  details,
  isPreorder,
  personalNote,
  presellDateUntil,
  presellMessage,
  productDescription,
  productIntro,
}) => {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse()
  const showReadMore = shouldShowReadMore(
    productDescription,
    details,
    careInstructions,
    personalNote
  )

  return (
    <div className="my-12">
      {isPreorder && (
        <div className="mb-10 text-fern-green">
          {isPreorder && (
            <div className="mb-3 text-xs lg:text-sm uppercase leading-none">
              <Translate id="availableForPreorder" />
            </div>
          )}
          <div className="max-w-md 3xl:max-w-lg leading-tight lg:text-xl 3xl:text-2xl lg:tracking-tight">
            {presellMessage}
          </div>
          <div className="mt-4 text-xs lg:text-sm uppercase leading-none">
            <Translate id="ends" />
            &nbsp;
            {presellDateUntil}
          </div>
        </div>
      )}
      {collabInfo?.html && (
        <div
          className={`mb-10 text-fern-green max-w-md 3xl:max-w-xl leading-tight lg:text-lg 3xl:text-xl lg:tracking-tight ${content}`}
          dangerouslySetInnerHTML={{ __html: collabInfo.html }}
        />
      )}
      {productIntro && (
        <div className="max-w-md 3xl:max-w-lg leading-tight lg:text-lg 3xl:text-xl lg:tracking-tight">
          {productIntro}
        </div>
      )}
      {showReadMore && (
        <button
          type="button"
          className="inline-block mt-3 lg:mt-6 uppercase text-lg tracking-tight text-fern-green focus:outline-none"
          {...getToggleProps()}
        >
          <span
            className={`inline-block ${detailsCloseIcon}`}
            style={{ transform: `rotate(${isExpanded ? '45deg' : '0deg'})` }}
          >
            +
          </span>
          <span className="ml-1">
            <Translate id="readMore" />
          </span>
        </button>
      )}
      <div className="pt-8" {...getCollapseProps()}>
        {productDescription && (
          <div
            className={`text-sm max-w-md font-sans-regular ${content}`}
            dangerouslySetInnerHTML={{ __html: productDescription }}
          />
        )}
        {details.length > 0 && (
          <div className="my-12 sm:table text-sm leading-tight">
            {details.map(({ key, value }, i) => (
              <div key={i} className="sm:table-row">
                <div className="sm:table-cell w-56 opacity-50 pr-6 pb-1">
                  {key}
                </div>
                <div className="sm:table-cell max-w-xs pb-3">
                  {value || '—'}
                </div>
              </div>
            ))}
          </div>
        )}
        {careInstructions && (
          <>
            <div className="text-sm opacity-50 pb-2">
              <Translate id="careInstructions" />
            </div>
            <div
              className={`max-w-sm text-xs font-sans-regular ${content}`}
              dangerouslySetInnerHTML={{ __html: careInstructions }}
            />
          </>
        )}
        {/* TODO: link -> read more about the process */}
        {personalNote && (
          <div className="flex max-w-lg mt-16">
            <div className="pr-2 3xl:pr-5 text-6xl lg:text-7xl 3xl:text-8xl leading-none">
              “
            </div>
            <div
              className={content}
              dangerouslySetInnerHTML={{ __html: personalNote }}
            />
          </div>
        )}
      </div>
    </div>
  )
}

ProductDetails.propTypes = {
  careInstructions: PropTypes.string,
  collabInfo: PropTypes.shape({
    html: PropTypes.string,
  }),
  details: PropTypes.array,
  isPreorder: PropTypes.bool,
  personalNote: PropTypes.string,
  presellDateFormatted: PropTypes.string,
  presellDateUntil: PropTypes.string,
  presellMessage: PropTypes.string,
  productDescription: PropTypes.string,
  productIntro: PropTypes.string,
}

export default ProductDetails
