import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import { connect } from 'react-redux'

import { CheckoutContext } from '../../layouts/MainLayout'

const buttonClassName =
  'block w-full mt-2 py-3 font-500 text-white text-center uppercase tracking-wide text-sm bg-fern-green rounded-full transition duration-500 focus:outline-none focus:opacity-50'

const AddToCart = ({
  availableForSale,
  addVariantToCart,
  variantShopifyId,
  productShopifyId,
  shopifyClient,
}) => {
  const [available, setAvailability] = useState(availableForSale)
  const { checkout } = useContext(CheckoutContext)

  const isAddedToCart = () => {
    if (checkout && checkout.lineItems && checkout.lineItems.length) {
      const isInCart = checkout.lineItems.some(
        ({ variant: { id } }) => id === variantShopifyId
      )
      return isInCart
    }
    return false
  }

  useEffect(() => {
    if (shopifyClient) {
      shopifyClient.product.fetch(productShopifyId).then(({ attrs }) => {
        const isAvailable = attrs.availableForSale.value
        setAvailability(isAvailable)
      })
    }
  }, [shopifyClient, productShopifyId])

  return (
    <div className="2sm:max-w-xs">
      {available ? (
        <>
          {isAddedToCart() ? (
            <div className={`${buttonClassName} bg-harvest-gold`}>
              <Translate id="addedToCart" />
            </div>
          ) : (
            <button
              type="button"
              onClick={() => addVariantToCart(variantShopifyId, 1)}
              className={buttonClassName}
            >
              <Translate id="addToCart" />
            </button>
          )}
        </>
      ) : (
        <Translate id="soldOut" />
      )}
    </div>
  )
}

AddToCart.propTypes = {
  availableForSale: PropTypes.bool.isRequired,
  addVariantToCart: PropTypes.func.isRequired,
  variantShopifyId: PropTypes.string.isRequired,
  productShopifyId: PropTypes.string.isRequired,
  shopifyClient: PropTypes.object,
}

const mapStateToProps = ({ shopifyClient }) => {
  return { shopifyClient }
}

export default connect(mapStateToProps)(AddToCart)
