import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { connect } from 'react-redux'

import onPageRender from '../hocs/onPageRender'
import MobileGallery from '../components/MobileGallery/MobileGallery'
import DesktopGallery from '../components/DesktopGallery/DesktopGallery'
import LinkedProductSlider from '../components/LinkedProductSlider/LinkedProductSlider'
import AddToCart from '../components/AddToCart/AddToCart'
import { addToLastSeen } from '../actions/addToLastSeen'
import SectionTitle, {
  TITLE_TYPES,
} from '../components/SectionTitle/SectionTitle'
import ProductDetails from '../components/ProductDetails/ProductDetails'
import ProductTitle from '../components/ProductTitle/ProductTitle'
import Container from '../components/Container/Container'
import LastSeenProductsBackground from '../components/LastSeenProductsBackground/LastSeenProductsBackground'
import Seo from '../components/Seo/Seo'

const getDataForDetails = ({ design, subcategory, category, color = '' }) => {
  let title
  if (!!design) {
    const prefix =
      subcategory?.data?.title?.text || category?.data?.title?.text || null
    const suffix = design?.data?.title?.text || null
    title = `${prefix} ${suffix}`
  } else {
    title =
      subcategory?.data?.title?.text || category?.data?.title?.text || null
  }

  const productIntro =
    design?.data?.product_intro?.text ||
    subcategory?.data?.product_intro?.text ||
    category?.data?.product_intro?.text ||
    null
  const productDescription =
    design?.data?.product_description?.html ||
    subcategory?.data?.product_description?.html ||
    category?.data?.product_description?.html ||
    null
  const detailsData =
    design?.data?.details ||
    subcategory?.data?.details ||
    category?.data?.details ||
    []
  const careInstructions =
    design?.data?.care_instructions?.html ||
    subcategory?.data?.care_instructions?.html ||
    category?.data?.care_instructions?.html ||
    null
  const personalNote =
    design?.data?.personal_note?.html ||
    subcategory?.data?.personal_note?.html ||
    category?.data?.personal_note?.html ||
    null

  const details = detailsData.map(item => {
    const { value } = item
    return {
      ...item,
      ...(value ? { value: value.replace('${color}', color) } : null), // eslint-disable-line no-template-curly-in-string
    }
  })

  return {
    title,
    productIntro,
    productDescription,
    details,
    careInstructions,
    personalNote,
  }
}

const getVariant = (variants = [], sku = '') => {
  const { price, shopifyId: variantShopifyId } =
    variants.find(({ sku: variantSku }) => variantSku === sku) || {}

  return { price, variantShopifyId }
}

/**
 * TODO:
 * Formulari "out of stock"/"notify when available":
 * afegeix mail per rebre avís quan el producte estigui disponible de nou
 * https://apps.shopify.com/back-in-stock
 */

const Product = ({ data, addVariantToCart, lastSeen, addToLastSeen }) => {
  const {
    prismicProduct: {
      id,
      _previewable,
      data: {
        sku,
        color,
        main_gallery: mainGallery,
        presellDate,
        presellDateFormatted,
        presellDateUntil,
        presellMessage,
        collabInfo,
        design,
      },
    },
    shopifyProduct,
    randomProducts,
    allProductNodes,
    allShopifyProduct,
    subcategory,
    category,
  } = data
  const { shopifyId: productShopifyId, availableForSale, variants } =
    shopifyProduct || {}
  const { price, variantShopifyId } = getVariant(variants, sku)
  const {
    title,
    productIntro,
    productDescription,
    details,
    careInstructions,
    personalNote,
  } = getDataForDetails({
    design: design?.document,
    subcategory,
    category,
    color,
  })

  const isPreview = !!_previewable

  const gallery = mainGallery
    .map(({ image: { localFile, fluid: { src: previewSrc } = {} } }) => ({
      ...localFile,
      previewSrc,
    }))
    .filter(file => !!file)
    .filter(
      ({ childImageSharp, previewSrc }) => !(!childImageSharp && !previewSrc)
    )

  useEffect(() => {
    addToLastSeen(id)
  }, [addToLastSeen, id])

  const lastSeenProducts = allProductNodes.nodes.filter(
    ({ id: productId }) =>
      lastSeen.allIds.indexOf(productId) > -1 && productId !== id
  )

  const isPreorder = !!presellDate && new Date(presellDate) > new Date()

  return (
    <>
      <Seo
        title={`${title}${color ? ` ${color}` : ''}`}
        description={productIntro}
      />
      <div className="md:flex">
        <div className="md:w-1/2">
          <div className="md:hidden">
            <MobileGallery gallery={gallery} />
          </div>
          <div className="hidden md:block sticky top-0">
            <DesktopGallery gallery={gallery} isPreview={isPreview} />
          </div>
        </div>
        <div className="md:w-1/2 px-6 3xl:px-10">
          <div className="sticky top-0">
            <ProductTitle title={title} color={color} price={price?.amount} />
            {productShopifyId && (
              <AddToCart
                availableForSale={availableForSale}
                addVariantToCart={addVariantToCart}
                variantShopifyId={variantShopifyId}
                productShopifyId={productShopifyId}
              />
            )}
            <ProductDetails
              careInstructions={careInstructions}
              collabInfo={collabInfo}
              details={details}
              personalNote={personalNote}
              isPreorder={isPreorder}
              presellDateFormatted={presellDateFormatted}
              presellDateUntil={presellDateUntil}
              presellMessage={presellMessage}
              productDescription={productDescription}
              productIntro={productIntro}
            />
          </div>
        </div>
      </div>
      <div className="mt-16">
        {randomProducts.nodes.length > 0 && (
          <Container>
            <SectionTitle
              translationId="youMayAlsoLike"
              type={TITLE_TYPES.HANDWRITTEN_ALPHA}
            />
            <LinkedProductSlider
              id="randomProducts"
              addVariantToCart={addVariantToCart}
              allShopifyProduct={allShopifyProduct}
              nodes={randomProducts.nodes}
            />
          </Container>
        )}
        {lastSeenProducts.length > 0 && (
          <div className="relative py-20 bg-almond z-10">
            <div className="flex justify-center">
              <div className="w-full xs:max-w-sm xs:px-4 2sm:max-w-md sm:max-w-xl md:max-w-3xl lg:max-w-5xl 3xl:max-w-6xl">
                <SectionTitle translationId="lastSeenProducts" />
                <LinkedProductSlider
                  id="lastSeenProducts"
                  addVariantToCart={addVariantToCart}
                  allShopifyProduct={allShopifyProduct}
                  nodes={lastSeenProducts}
                />
              </div>
            </div>
            <LastSeenProductsBackground />
          </div>
        )}
      </div>
    </>
  )
}

Product.propTypes = {
  addVariantToCart: PropTypes.func,
  data: PropTypes.object.isRequired,
  lastSeen: PropTypes.object,
  addToLastSeen: PropTypes.func,
}

Product.defaultProps = {
  addVariantToCart: () => {},
}

const mapStateToProps = ({ lastSeen }) => {
  return { lastSeen }
}

const mapDispatchToProps = dispatch => {
  return {
    addToLastSeen: id => dispatch(addToLastSeen(id)),
  }
}

export default onPageRender(
  connect(mapStateToProps, mapDispatchToProps)(Product)
)

export const query = graphql`
  query(
    $id: String!
    $sku: String!
    $randomProducts: [ID]!
    $lang: String!
    $prismicIdRegexStr: String!
  ) {
    prismicProduct(id: { eq: $id }) {
      id
      data {
        sku
        title {
          text
        }
        color
        main_gallery {
          image {
            localFile {
              id
              childImageSharp {
                gatsbyImageData(
                  quality: 85
                  placeholder: NONE
                  layout: FULL_WIDTH
                )
              }
            }
          }
        }
        design {
          document {
            ... on PrismicDesign {
              id
              data {
                title {
                  text
                }
                product_intro {
                  text
                }
                product_description {
                  html
                }
                details {
                  key
                  value
                }
                care_instructions {
                  html
                }
                personal_note {
                  html
                }
              }
            }
          }
        }
        presellDate: presell_end_date(formatString: "YYYY-MM-DDTHH:mm:ss.SSS")
        presellDateFormatted: presell_end_date(
          formatString: "LL"
          locale: $lang
        )
        presellDateUntil: presell_end_date(locale: $lang, fromNow: true)
        presellMessage: presell_message
        collabInfo: collab_info {
          html
        }
      }
    }
    shopifyProduct(variants: { elemMatch: { sku: { eq: $sku } } }) {
      shopifyId
      availableForSale
      variants {
        sku
        price {
          amount
        }
        shopifyId
      }
    }
    subcategory: prismicSubcategory(dataString: { regex: $prismicIdRegexStr }) {
      data {
        title {
          text
        }
        product_intro {
          text
        }
        product_description {
          html
        }
        details {
          key
          value
        }
        care_instructions {
          html
        }
        personal_note {
          html
        }
      }
    }
    category: prismicCategory(dataString: { regex: $prismicIdRegexStr }) {
      data {
        title {
          text
        }
        product_intro {
          text
        }
        product_description {
          html
        }
        details {
          key
          value
        }
        care_instructions {
          html
        }
        personal_note {
          html
        }
      }
    }
    randomProducts: allPrismicProduct(
      filter: { prismicId: { in: $randomProducts }, lang: { eq: $lang } }
    ) {
      nodes {
        ...LinkedPrismicProduct
      }
    }
    allProductNodes: allPrismicProduct(filter: { lang: { eq: $lang } }) {
      nodes {
        ...LinkedPrismicProduct
      }
    }
    allShopifyProduct {
      edges {
        node {
          variants {
            sku
            price {
              amount
            }
            shopifyId
            availableForSale
          }
        }
      }
    }
  }
`
