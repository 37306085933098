import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

const LastSeenProductsBackground = () => (
  <div
    className="absolute right-0 bottom-0 w-1/2 md:w-1/3 lg:w-1/4 pointer-events-none"
    style={{ zIndex: '-1' }}
  >
    <StaticImage
      src="./assets/last-seen-products.png"
      alt=""
      className="block select-none"
      placeholder="none"
      quality={100}
      style={{ display: 'block' }}
    />
  </div>
)

export default LastSeenProductsBackground
